exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-compra-js": () => import("./../../../src/pages/compra.js" /* webpackChunkName: "component---src-pages-compra-js" */),
  "component---src-pages-entre-2-6-a-js": () => import("./../../../src/pages/entre2_6a.js" /* webpackChunkName: "component---src-pages-entre-2-6-a-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-mantenimientos-js": () => import("./../../../src/pages/mantenimientos.js" /* webpackChunkName: "component---src-pages-mantenimientos-js" */),
  "component---src-pages-mas-6-a-js": () => import("./../../../src/pages/mas_6a.js" /* webpackChunkName: "component---src-pages-mas-6-a-js" */),
  "component---src-pages-mascotas-js": () => import("./../../../src/pages/mascotas.js" /* webpackChunkName: "component---src-pages-mascotas-js" */),
  "component---src-pages-menos-3-a-js": () => import("./../../../src/pages/menos_3a.js" /* webpackChunkName: "component---src-pages-menos-3-a-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-pagando-js": () => import("./../../../src/pages/pagando.js" /* webpackChunkName: "component---src-pages-pagando-js" */),
  "component---src-pages-pagar-js": () => import("./../../../src/pages/pagar.js" /* webpackChunkName: "component---src-pages-pagar-js" */),
  "component---src-pages-parques-js": () => import("./../../../src/pages/parques.js" /* webpackChunkName: "component---src-pages-parques-js" */),
  "component---src-pages-renta-js": () => import("./../../../src/pages/renta.js" /* webpackChunkName: "component---src-pages-renta-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

