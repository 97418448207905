import './src/styles/global.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/600.css';
import '@fontsource/ubuntu/300.css';
import '@fontsource/ubuntu/300-italic.css';
import '@fontsource/ubuntu/400.css';
import '@fontsource/ubuntu/500.css';
import 'react-multi-carousel/lib/styles.css';
import { CartProviderWrapper as CartProvider} from "./src/context/wrap-with-provider";

export const wrapRootElement = CartProvider;